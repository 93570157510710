<template>
    <div>
        <b-card>
            <b-card-header header-class="pt-0">
                <div
                    class="flex justify-content-end align-content-center gap-x-2"
                >
                    <h4>تفاصيل المرحلة</h4>
                    <unicon
                        name="university"
                        fill="#2EC4B6"
                        style="margin-top: -10px"
                    ></unicon>
                </div>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="12" md="6" lg="6">
                        <ek-input-text
                            label=" اسم الصف "
                            name="name"
                            placeholder="ادخل اسم الصف"
                            v-model="detailsFaculty.name"
                        >
                        </ek-input-text>

                        <ek-input-text
                            name="subjectsCount"
                            type="number"
                            label=" عدد المواد "
                            placeholder="عدد المواد"
                            v-model="detailsFaculty.subjectsCount"
                        >
                        </ek-input-text>
                    </b-col>
                    <b-col cols="12" md="6" lg="6">
                        <ek-input-text
                            name="dateCreated"
                            label=" تاريخ الاضافة "
                            placeholder="تاريخ الاضافة"
                            :value="
                                new Date(
                                    detailsFaculty.dateCreated
                                ).toLocaleDateString()
                            "
                        >
                        </ek-input-text>

                        <ek-input-text
                            name="studentsCount"
                            type="number"
                            label=" عدد الطلاب المسجلين "
                            placeholder="عدد الطلاب المسجلين"
                            v-model="detailsFaculty.studentsCount"
                        >
                        </ek-input-text>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <b-card>
            <b-card-header header-class="pt-0">
                <div class="flex justify-content-end align-content-center">
                    <h4>المواد</h4>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="#2EC4B6"
                            d="M9 3v15h3V3zm3 2l4 13l3-1l-4-13zM5 5v13h3V5zM3 19v2h18v-2z"
                        />
                    </svg>
                </div>
            </b-card-header>
            <b-card-body>
                <ek-table
                    :columns="classesColumn"
                    :items="detailsFaculty.subjects"
                    @details="detailsAppPanel"
                    :no_select="true"
                    :no_delete="true"
                >
                    <template slot="items.dateCreated" slot-scope="{ value }">
                        <span> {{ new Date(value).toLocaleDateString() }}</span>
                    </template>
                    <template slot="items.type" slot-scope="{ value }">
                        <span v-if="value == 1">مرحلة التعليم المدرسي </span>
                    </template>
                    <template slot="items.id" slot-scope="{ value }">
                        <b-button
                            size="sm"
                            variant="flat-secondary"
                            class="btn-icon rounded-circle"
                            style="padding: 2px 6px !important"
                            @click="goToDetails(value)"
                        >
                            <unicon name="ellipsis-v" width="18"></unicon>
                        </b-button>
                    </template>
                </ek-table>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
    data() {
        return {
            tab: 0,
        };
    },
    components: {},
    computed: {
        ...mapState({
            classesColumn: (state) => state.faculty.classesColumn,
            detailsFaculty: (state) => state.faculty.detailsFaculty,
        }),
        ...mapGetters(["universities"]),
    },
    methods: {
        ...mapActions(["getGetUnRead", "getFacultyDetails"]),
        detailsAppPanel({ row }) {
            this.$store.commit("IS_DIALOG_OPEN", true);
            this.$store.commit("SET_UNIVERSITY_DTO", row);
        },
        deleteUni(e) {
            this.$store.dispatch("deleteUniversities", e);
        },
        goToDetails(id) {
            console.log(id);
        },
    },
    created() {
        this.getFacultyDetails(this.$route.params.id);
    },
};
</script>
